import { ApiURI } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import {
    AddUserCouponsRequest,
    Coupon,
    CouponCode,
    CreateCouponCodesRequest, UserCoupon, UserCouponItem
} from 'src/core/models/coffee-core/coupon.model';
import ResponseCustom from 'src/core/models/response-custom.model';

export default class CouponRepository extends BaseRepository {

    getListCouponByTypes(types: string[], tenantId: number): Promise<AxiosResponse<Coupon[]>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/coupon/getListCouponByTypes`).query<Coupon[]>({types: types.join(','), tenantId})
    }

    addUserCoupons(request: AddUserCouponsRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/coupon/addUserCoupons`).create<ResponseCustom>(request)
    }

    getListCouponCode(status: string, tenantId: number): Promise<AxiosResponse<CouponCode[]>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/coupon/getListCouponCode`).query<CouponCode[]>({status, tenantId});
    }

    updateCouponCodeStatus(couponCodeIds: number[], status: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/coupon/updateCouponCodeStatus`).create<ResponseCustom>({couponCodeIds, status});
    }

    createCouponCodes(request: CreateCouponCodesRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/coupon/createCouponCodes`).create<ResponseCustom>(request);
    }

    getUserCouponById(id: number): Promise<AxiosResponse<UserCoupon>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/coupon/getUserCouponById`).query<UserCoupon>({id});
    }
}
