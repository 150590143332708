import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { QrReader } from 'react-qr-reader';
import useSound from 'use-sound';
import beepSound from 'src/shared/assets/sound/beep-sound.mp3';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    onScanSuccess: (value: string) => void,
}

const ScanQRCodeModal = (props: Props): JSX.Element => {
    const [play] = useSound(beepSound);

    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
        >
            <MDBModalHeader>Quét mã QR</MDBModalHeader>
            <MDBModalBody>
                <QrReader
                    onResult={(result, error) => {
                        if (result) {
                            play()
                            props.onScanSuccess(result.getText())
                        }
                    }}
                    constraints={{aspectRatio: 1}}
                />
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='primary' outline onClick={() => props.setIsVisible(false)}>Huỷ</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default ScanQRCodeModal;
