export const extractCouponQRValue = (qrValue: string): [string, string, number, number?] => {
    const splits = qrValue.split('|')
    const couponType = splits[0]
    const couponCode = splits[1]
    const userId = Number(splits[2])
    let userCouponId
    if (splits.length === 4) {
        userCouponId = Number(splits[3])
    }
    return [couponType, couponCode, userId, userCouponId]
}
