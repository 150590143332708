import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/coffee-core/user/user.type';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';
import { User } from 'src/core/models/coffee-core/user.model';

const userRepository = Repositories[RepositoryName.USER];

export const actGetUserByCode = (code: string, tenantId: number, successCallback = (user: User) => {
}) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_USER_REQUEST, Types.GET_USER_SUCCESS, Types.GET_USER_FAILURE],
        callAPI: () => userRepository.getUserByCode(code, tenantId),
        callBack: {
            success: (response: AxiosResponse<User>) => {
                successCallback(response.data);
            },
            failure: (error: AxiosError) => {
                const response = error.response?.data;
                if (response?.messages[0]?.code === 'USER_NOT_FOUND') {
                    toast.error('Không tìm thấy user', {duration: 2000})
                    return
                }
                toast.error('Tìm kiếm user thất bại', {duration: 2000})
            }
        }
    })
};

export const actGetUserByPhone = (phone: string, tenantId: number, successCallback = (user: User) => {
}) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_USER_REQUEST, Types.GET_USER_SUCCESS, Types.GET_USER_FAILURE],
        callAPI: () => userRepository.getUserByPhone(phone, tenantId),
        callBack: {
            success: (response: AxiosResponse<User>) => {
                successCallback(response.data);
            },
            failure: (error: AxiosError) => {
                const response = error.response?.data;
                if (response?.messages[0]?.code === 'USER_NOT_FOUND') {
                    toast.error('Không tìm thấy user', {duration: 2000})
                    return
                }
                toast.error('Tìm kiếm user thất bại', {duration: 2000})
            }
        }
    })
};

export const actGetUserById = (userId: number, tenantId: number, successCallback = (user: User) => {
}) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_USER_REQUEST, Types.GET_USER_SUCCESS, Types.GET_USER_FAILURE],
        callAPI: () => userRepository.getUserById(userId, tenantId),
        callBack: {
            success: (response: AxiosResponse<User>) => {
                successCallback(response.data);
            },
            failure: (error: AxiosError) => {
                const response = error.response?.data;
                if (response?.messages[0]?.code === 'USER_NOT_FOUND') {
                    toast.error('Không tìm thấy user', {duration: 2000})
                    return
                }
                toast.error('Tìm kiếm user thất bại', {duration: 2000})
            }
        }
    })
};

export const actResetUser = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_USER,
    })
};
